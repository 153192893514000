<template>
  <div class="container">
    <h1>Edit Wine</h1>
    <div class="mb-3">
      <label for="formFile" class="form-label"
        >Foto der Weinflasche hochladen (PNG, freigestellt, Hintergrund
        transparent)</label
      >
      <input
        class="form-control"
        type="file"
        id="formFile"
        ref="fileInput"
        @input="inputFile"
      />
    </div>
    <img v-if="previewImage" :src="previewImage" />
    <!-- <img v-if="previewImage" :src="`data:image/png;base64,${previewImage}`" /> -->
    <!-- <img :src="`data:image/png;base64,${bottle.image}`" /> -->
    <div class="mb-3">
      <label for="inputName" class="form-label">Name</label>
      <input
        type="text"
        class="form-control"
        id="inputName"
        placeholder="Name des Weins"
        v-model="name"
      />
    </div>
    <div class="mb-3">
      <label for="inputType" class="form-label">Art</label>
      <div>
        <div class="btn-group" role="group" id="inputType">
          <input
            type="radio"
            class="btn-check"
            name="btnradio"
            id="btnradio1"
            autocomplete="off"
            value="white"
            v-model="type"
          />
          <label class="btn btn-outline-primary" for="btnradio1"
            >Weißwein</label
          >

          <input
            type="radio"
            class="btn-check"
            name="btnradio"
            id="btnradio2"
            autocomplete="off"
            value="red"
            v-model="type"
          />
          <label class="btn btn-outline-primary" for="btnradio2">Rotwein</label>

          <input
            type="radio"
            class="btn-check"
            name="btnradio"
            id="btnradio21"
            autocomplete="off"
            value="rose"
            v-model="type"
          />
          <label class="btn btn-outline-primary" for="btnradio21">Rosé</label>

          <input
            type="radio"
            class="btn-check"
            name="btnradio"
            id="btnradio3"
            autocomplete="off"
            value="sparkling"
            v-model="type"
          />
          <label class="btn btn-outline-primary" for="btnradio3"
            >Schaumwein</label
          >

          <input
            type="radio"
            class="btn-check"
            name="btnradio"
            id="btnradio4"
            autocomplete="off"
            value="spirits"
            v-model="type"
          />
          <label class="btn btn-outline-primary" for="btnradio4"
            >Spirituosen</label
          >
        </div>
        {{ type }}
      </div>
    </div>
    <div class="mb-3">
      <label for="inputArticleNumber" class="form-label">Artikelnummer</label>
      <input
        type="text"
        class="form-control"
        id="inputArticleNumber"
        placeholder="Artikelnummer aus dem Bottle-Shock Shop"
        v-model="articleNumber"
      />
    </div>
    <div class="mb-3">
      <label for="inputDescription" class="form-label">Beschreibung</label>
      <textarea
        v-model="description"
        class="form-control"
        id="inputDescription"
        rows="5"
      ></textarea>
    </div>
    <div class="mb-3">
      <label for="inputPrice" class="form-label">Preis</label>
      <input v-model="price" type="text" class="form-control" id="inputPrice" />
    </div>
    <div class="btn-group" role="group" aria-label="Basic example">
      <button type="button" class="btn btn-primary" @click="save()">
        Save
      </button>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { ref } from "vue";

export default {
  props: ["bottleId"],
  setup() {
    return {
      bottle: ref(null),
      previewImage: ref(null),
      previewFile: ref(null),
      name: ref(null),
      articleNumber: ref(null),
      description: ref(null),
      price: ref(null),
      type: ref("white"),
    };
  },
  methods: {
    getImage(col, row) {
      const bottle = this.bottles.find((b) => b.column == col && b.row == row);
      if (bottle) {
        return bottle.image;
      }
      // return require("../assets/bottle.png");
    },
    isActive(col, row) {
      if (col == 7 && row == 4) {
        return true;
      }
      return false;
    },
    inputFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        this.previewFile = file[0];
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
      }
    },
    save() {
      var formData = new FormData();
      // var imagefile = document.querySelector("#file");
      // formData.append("image", imagefile.files[0]);
      // formData.append("file", this.previewImage);
      formData.append("image", this.previewFile);

      formData.append("id", this.bottleId);
      formData.append("name", this.name);
      formData.append("type", this.type);
      formData.append("articleNumber", this.articleNumber);
      formData.append("description", this.description);
      formData.append("price", this.price);
      formData.append("imageId", this.bottle.imageId);
      axios.post(`/api/bottles/${this.bottleId}`, formData, {
        // headers: {
        //   "Content-Type": "multipart/form-data",
        // },
      });

      // axios.post("/api/bottles", {
      //   name: this.name,
      //   description: this.description,
      //   price: this.price,
      //   image: this.previewImage,
      // });

      this.$router.push({ name: "Bottles" });
    },
  },
  async mounted() {
    const response = await axios.get(`/api/bottles/${this.bottleId}`);
    console.log(response.data);
    const bottle = response.data;
    this.name = bottle.name;
    this.type = bottle.type;
    this.articleNumber = bottle.articleNumber;
    this.description = bottle.description;
    this.previewImage = `data:image/png;base64,${bottle.image}`;
    this.price = bottle.price;
    this.bottle = bottle;
    console.log(this.bottleId);
  },
};
</script>
<style scoped>
img {
  width: 200px;
}
</style>
