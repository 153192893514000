<template>
  <div class="about">
    <h1>Wein auswählen für Fach (x,y)</h1>

    <div class="row row-cols-1 row-cols-md-3 row-cols-xl-4 row-cols-xxl-5 g-4">
      <div class="col card-group" v-for="bottle in bottles" :key="bottle.id">
        <div class="card" style="width: 18rem">
          <div class="card-body">
            <img :src="`data:image/png;base64,${bottle.image}`" />
            <h5 class="card-title">{{ bottle.name }}</h5>
            <p class="card-text">
              {{ bottle.description }}
            </p>
            <p class="card-text">
              {{ bottle.price }}
            </p>
          </div>
          <div class="card-footer">
            <a @click="chooseBottle(bottle.id)" class="btn btn-primary">Use</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { ref } from "vue";

export default {
  props: ["column", "row"],
  setup() {
    return {
      bottles: ref([]),
    };
  },
  methods: {
    getImage(col, row) {
      const bottle = this.bottles.find((b) => b.column == col && b.row == row);
      if (bottle) {
        return bottle.image;
      }
    },
    async load() {
      const response = await axios.get("/api/bottles");
      console.log(response.data);
      this.bottles = response.data;
    },
    async chooseBottle(id) {
      const response = await axios.post("/api/compartments", {
        wineId: id,
        column: this.column,
        row: this.row,
      });
      console.log(response.data);
      this.$router.push({ name: "Shop" });
    },
  },
  async mounted() {
    await this.load();
  },
};
</script>
<style scoped>
img {
  width: 200px;
}
</style>
