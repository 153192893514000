<template>
  <div class="about">
    <h1>Weine</h1>

    <div class="btn-group" role="group" aria-label="Basic example">
      <router-link to="/bottles/new">
        <button type="button" class="btn btn-primary m-2">New</button>
      </router-link>
      <!-- <button type="button" class="btn btn-primary">Middle</button>
      <button type="button" class="btn btn-primary">Right</button> -->
    </div>

    <!-- <div class="card-group"> -->
    <div class="row row-cols-1 row-cols-md-3 row-cols-xl-4 row-cols-xxl-6 g-4">
      <div class="col card-group" v-for="bottle in bottles" :key="bottle.id">
        <div class="card" style="width: 18rem">
          <!-- <img src="" class="card-img-top" alt="..." /> -->
          <div class="card-body">
            <img :src="`data:image/png;base64,${bottle.image}`" />
            <h5 class="card-title">{{ bottle.name }}</h5>
            <p class="card-text">
              {{ bottle.description }}
            </p>
            <p class="card-text">
              {{ bottle.articleNumber }}
            </p>
            <p class="card-text">
              {{ bottle.price }}
            </p>
            <p class="card-text">
              {{ bottle.type }}
            </p>
          </div>
          <div class="card-footer">
            <router-link
              :to="{ name: 'BottleEdit', params: { bottleId: bottle.id } }"
              class="btn btn-primary mx-2"
              >Edit</router-link
            >
            <a @click="deleteBottle(bottle.id)" class="btn btn-primary"
              >Delete</a
            >
          </div>
        </div>
      </div>
    </div>

    <!-- </div> -->
  </div>
</template>
<script>
import axios from "axios";
import { ref } from "vue";

export default {
  setup() {
    return {
      bottles: ref([]),
    };
  },
  methods: {
    getImage(col, row) {
      const bottle = this.bottles.find((b) => b.column == col && b.row == row);
      if (bottle) {
        return bottle.image;
      }
      // return require("../assets/bottle.png");
    },
    isActive(col, row) {
      if (col == 7 && row == 4) {
        return true;
      }
      return false;
    },
    async deleteBottle(id) {
      await axios.delete(`/api/bottles/${id}`);
      await this.load();
    },
    async load() {
      const response = await axios.get("/api/bottles");
      console.log(response.data);
      this.bottles = response.data;
    },
  },
  async mounted() {
    await this.load();
  },
};
</script>
<style scoped>
img {
  width: 100px;
}
</style>
